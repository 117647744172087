<template>
  <v-dialog width="800px" v-model="altDialog[index]" persistent>
    <v-card class="pa-3">
      <v-container>
        <!--Header-->
        <v-row>
          <v-col cols="12">
            <v-spacer></v-spacer
            ><v-btn
              small
              class="float-right"
              icon
              @click="$emit('closeAltModal')"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
            <h3>Edit Image alt text</h3>
            <v-divider class="my-3"></v-divider>
          </v-col>
        </v-row>
        <!--Form Body-->
        <v-row>
          <!--Image preview-->
          <v-col md="4">
            <v-img width="100%" max-width="100%" :src="image.url"></v-img>
          </v-col>
          <!--Alt input field-->
          <v-col md="8">
            <p>Image alt text</p>
            <v-text-field outlined dense v-model="altText"></v-text-field>
            <small
              >Write a short description of this image to improve Search Engine
              Optimisation (SEO) and assist visually impaired visitors.
            </small>
          </v-col>
        </v-row>
        <!--Action Buttons-->
        <v-row>
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-btn
              class="float-right"
              color="primary"
              @click="
                $emit('saveImgAlt', altText);
                $emit('closeAltModal');
              "
              >Save Alt text</v-btn
            >
            <v-btn class="float-right" text @click="$emit('closeAltModal')"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AltTagModal",
  props: ["altDialog", "image", "index"],
  data() {
    return {
      altText: this.image.alt,
    };
  },
};
</script>